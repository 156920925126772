import auth from './modules/auth/auth';
import hits from './modules/hits/hits';
import users from './modules/users/users';
import phrases from './modules/phrases/phrases';
import packages from './modules/services/packages';
import services from './modules/services/services';
import baseOffers from './modules/offers/baseOffers';
import isbOffers from './modules/offers/isbOffers';
import urlShortener from './modules/url-shortener/shortener';
import productTypes from './modules/products/types';
import products from './modules/products/products';
import notificator from './modules/site-notifications/notificator';
// import flash from '@shared/stores/modules/flash' // @TODO проверить необходимость и подключить глобально
import mainServices from './modules/services/mainServices';
import additionalServices from './modules/services/additionalServices';
// import sharedStore from '@shared/stores/modules/sharedStore' // @TODO проверить необходимость и подключить глобально
import serviceTypes from './modules/services/serviceTypes';
import subserviceTexts from './modules/services/subserviceTexts';
import subserviceSelects from './modules/services/subserviceSelects';
import subserviceOptions from './modules/services/subserviceOptions';
import singleServices from './modules/services/singleServices';
import blockTypes from './modules/services/blockTypes';
import advantages from './modules/services/advantages';
import advantageItems from './modules/services/advantageItems';
import slides from './modules/slides/slides';
import sliders from './modules/slides/sliders';
import rateDetails from './modules/services/rateDetails';
import communicationChannels from './modules/services/communicationChannels';
import boosters from './modules/booster/booster';
import recurrentPayments from './modules/recurrent-payments/recurrent-payments';
import knowledgeSubCategory from './modules/knowledge/subCategory';
import knowledgeCategory from './modules/knowledge/category';
import knowledgeQuestion from './modules/knowledge/question';
import knowledgeAnswer from './modules/knowledge/answer';
import knowledgeVideo from './modules/knowledge/video';
import menuSections from './modules/menu/sections';
import publications from './modules/pages/publications';
import pages from './modules/pages/pages';
import pageCatalog from './modules/pages/pageCatalogs';
import purchases from './modules/purchases/purchases';
import purchasePages from './modules/purchases/purchasePages';
// import regions from '@shared/stores/modules/regions' // @TODO проверить необходимость и подключить глобально
import auction from './modules/auction/auction';
import auctionPages from './modules/auction/auctionPages';
import news from './modules/news/news';
import areas from './modules/areas/areas';
import newsCategory from './modules/news/category';
import operators from './modules/operator/operator';
import bonuses from './modules/bonus/bonus';
import directions from './modules/news/directions';
import tvRubrics from './modules/tv/rubrics';
import cities from './modules/cities/cities';
import channels from './modules/channels/channels';
import videoRequest from './modules/tv/videoRequest';
import channelCategory from './modules/tv/channelCategory';
import channelSources from './modules/channels/sources';
import blocks from './modules/services/blocks';
import blockItems from './modules/services/blockItems';
import technicalCheckRules from './modules/technical/rules';
import uiSettings from './modules/user-interface/settings';
import uiMain from './modules/user-interface/main';
import pushCampaign from './modules/push-campaign/push-campaign';
import pssCategory from './modules/pss/category';

export default {
  namespaced: true,
  modules: {
    auth,
    users,
    menuSections,
    hits,
    phrases,
    packages,
    services,
    mainServices,
    additionalServices,
    serviceTypes,
    subserviceTexts,
    subserviceSelects,
    subserviceOptions,
    singleServices,
    blockTypes,
    advantages,
    advantageItems,
    slides,
    sliders,
    rateDetails,
    communicationChannels,
    boosters,
    'knowledge/subCategory': knowledgeSubCategory,
    'knowledge/category': knowledgeCategory,
    'knowledge/question': knowledgeQuestion,
    'knowledge/answer': knowledgeAnswer,
    'knowledge/video': knowledgeVideo,
    pages,
    pageCatalog,
    publications,
    purchases,
    purchasePages,
    auction,
    auctionPages,
    directions,
    news,
    areas,
    operators,
    bonuses,
    'news/category': newsCategory,
    'tv/rubrics': tvRubrics,
    'tv/videoRequest': videoRequest,
    'tv/channelCategory': channelCategory,
    'tv/channelSources': channelSources,
    cities,
    channels,
    blocks,
    blockItems,
    technicalCheckRules,
    uiSettings,
    uiMain,
    baseOffers,
    isbOffers,
    urlShortener,
    productTypes,
    products,
    notificator,
    recurrentPayments,
    pushCampaign,
    'pss/category': pssCategory
  }
};
