import api from '@/services/gateway/api';

const state = {
  user: {
    id: '',
    email: '',
    username: '',
    token: '',
    expiresAt: ''
  },
  loading: false,
  error: null,
  menu: []
};

const getters = {
  getUserToken: state => state.user.token,
  getAuthLoading: state => state.loading,
  getAuthError (state) {
    if (state.error !== null && state.error.message) {
      return state.error.message;
    }
    if (state.error) {
      return state.error.error;
    }
  },
  getUser: state => state.user,
  getMenu: (state) => state.menu,
  isLoggedIn: state => !!state.user.token,
  getExpiresAt: (state) => {
    return state.user.expiresAt;
  },
  isTokenExpired: (state) => {
    return state.user.expiresAt < Math.round((Date.now() / 1000));
  }
};

const mutations = {
  setUser (state, data) {
    state.user.expiresAt = data['expires_at'];
    state.user.token = data.token;
    state.user.id = data.user.id;
    state.user.email = data.user.email;
    state.user.username = data.user.username;
  },
  removeUser (state) {
    state.user.token = '';
    state.user.expiresAt = '';
  },
  setAuthLoading (state, payload) {
    state.loading = payload;
  },
  setAuthError (state, payload) {
    state.error = payload;
  },
  clearAuthError (state) {
    state.error = null;
  },
  setMenu (state, data) {
    state.menu = data;
  }
};

const actions = {
  signIn ({ commit }, payload) {
    commit('setAuthLoading', true);
    commit('clearAuthError');
    api.post(
      'camp/token',
      {
        email: payload.email,
        password: payload.password
      }
    ).then((response) => {
      commit('setAuthLoading', false);
      commit('setUser', response.data);
    }
    ).catch((error) => {
      commit('setAuthLoading', false);
      commit('setAuthError', error.response.data);
    });
  },
  setUser ({ commit }, payload) {
    commit('setUser', payload);
  },
  clearAuthError ({ commit }) {
    commit('clearAuthError');
  },
  actionGetMenu ({ commit }, payload) {
    return api.get('camp/menu').then((response) => {
      commit('setMenu', response.data);
    }
    );
  },
  removeUser ({ commit }) {
    commit('removeUser');
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
