import { campGuestMiddleware } from '@/vue-instance/router/middlewares/camp-guest-middleware';
import { campAuthMiddleware } from '@/vue-instance/router/middlewares/camp-auth-middleware';
import store from '@/vue-instance/store';

const emptyRouterView = {
  render (c) {
    return c('router-view');
  }
};

export default [
  {
    path: 'auth/login',
    beforeEnter: (to, from, next) => {
      campGuestMiddleware(to, from, next);
      next();
    },
    component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/auth/Login.vue'),
    name: 'camp.auth.login'
  },
  {
    path: '',
    component: () => import(/* webpackChunkName: 'camp' */ '@/components/camp/TheDashboardContainer.vue'),
    children: [
      {
        path: '',
        component: emptyRouterView,
        beforeEnter: (to, from, next) => {
          store.dispatch('camp/uiMain/closeMobileMenu');
          campAuthMiddleware(to, from, next);

          next();
        },
        children: [
          {
            path: 'dashboard',
            name: 'camp.dashboard',
            component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/Dashboard.vue'),
            meta: {
              label: 'Главная страница'
            }
          },
          {
            path: 'users',
            meta: {
              label: 'Пользователи'
            },
            name: 'camp.users.group',
            redirect: { name: 'camp.users' },
            component: emptyRouterView,
            children: [
              {
                path: '',
                name: 'camp.users',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/users/UsersIndex.vue'),
                meta: {
                  label: 'Список'
                }
              },
              {
                path: 'add',
                name: 'camp.users.add',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/users/UsersAdd.vue'),
                meta: {
                  label: 'Добавление'
                }
              },
              {
                path: 'edit/:userId',
                name: 'camp.users.edit',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/users/UsersEdit.vue'),
                meta: {
                  label: 'Редактирование'
                }
              }
            ]
          },
          {
            path: 'offers',
            meta: {
              label: 'Предложения'
            },
            name: 'camp.offers.base.group',
            redirect: { name: 'camp.offers.base.index' },
            component: emptyRouterView,
            children: [
              {
                path: 'base',
                name: 'camp.offers.base.index',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/offers/BaseList.vue'),
                meta: {
                  label: 'Список'
                }
              },
              {
                path: 'base/create',
                name: 'camp.offers.base.create',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/offers/BaseCreate.vue'),
                meta: {
                  label: 'Добавление'
                }
              },
              {
                path: 'base/:offerId/edit',
                name: 'camp.offers.base.edit',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/offers/BaseEdit.vue'),
                meta: {
                  label: 'Редактирование'
                }
              }
            ]
          },
          {
            path: 'boosters',
            meta: {
              label: 'Бустеры'
            },
            name: 'camp.boosters.group',
            redirect: { name: 'camp.boosters.index' },
            component: emptyRouterView,
            children: [
              {
                path: '',
                name: 'camp.boosters.index',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/boosters/BoostersList.vue'),
                meta: {
                  label: 'Список'
                }
              }
            ]
          },
          {
            path: 'notifications',
            meta: {
              label: 'Оповещения'
            },
            name: 'camp.site.notifications.group',
            redirect: { name: 'camp.site.notifications.index' },
            component: emptyRouterView,
            children: [
              {
                path: '/',
                name: 'camp.site.notifications.index',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/site-notifications/List.vue'),
                meta: {
                  label: 'Список'
                }
              },
              {
                path: 'notifications/create',
                name: 'camp.site.notifications.create',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/site-notifications/Create.vue'),
                meta: {
                  label: 'Добавление'
                }
              },
              {
                path: 'notifications/:id/edit',
                name: 'camp.site.notifications.edit',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/site-notifications/Edit.vue'),
                meta: {
                  label: 'Редактирование'
                }
              }
            ]
          },
          {
            path: 'products',
            meta: {
              label: 'Типы продуктов'
            },
            name: 'camp.products.types.group',
            redirect: { name: 'camp.products.types.index' },
            component: emptyRouterView,
            children: [
              {
                path: 'types',
                name: 'camp.products.types.index',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/products/ProductList.vue'),
                meta: {
                  label: 'Список'
                }
              },
              {
                path: 'types/:id/edit',
                name: 'camp.products.types.edit',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/products/ProductEdit.vue'),
                meta: {
                  label: 'Редактирование'
                }
              }
            ]
          },

          {
            path: 'cache',
            meta: {
              label: 'Типы продуктов'
            },
            name: 'camp.services.cache.group',
            redirect: { name: 'camp.services.cache.index' },
            component: emptyRouterView,
            children: [
              {
                path: 'types',
                name: 'camp.services.cache.index',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/customer-services/CacheIndex.vue'),
                meta: {
                  label: 'Cache'
                }
              }
            ]
          },

          {
            path: 'url-shortener',
            meta: {
              label: 'Генерация которких ссылок'
            },
            name: 'camp.short-links.group',
            redirect: { name: 'camp.short-links.index' },
            component: emptyRouterView,
            children: [
              {
                path: '/',
                name: 'camp.short-links.index',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/url-shortener/UrlShortenerList.vue'),
                meta: {
                  label: 'Список'
                }
              },
              {
                path: 'create',
                name: 'camp.short-links.create',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/url-shortener/UrlShortenerCreate.vue'),
                meta: {
                  label: 'Добавление'
                }
              },
              {
                path: 'edit/:id',
                name: 'camp.short-links.edit',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/url-shortener/UrlShortenerEdit.vue'),
                meta: {
                  label: 'Редактирование'
                }
              }
            ]
          },
          {
            path: 'hits',
            meta: {
              label: 'Хиты продаж'
            },
            name: 'camp.hits.group',
            redirect: { name: 'camp.hits' },
            component: emptyRouterView,
            children: [
              {
                path: '',
                name: 'camp.hits',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/hits/HitsIndex.vue'),
                meta: {
                  label: 'Список'
                }
              },
              {
                path: 'add',
                name: 'camp.hits.add',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/hits/HitsAdd.vue'),
                meta: {
                  label: 'Добавление'
                }
              },
              {
                path: 'edit/:id',
                name: 'camp.hits.edit',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/hits/HitsEdit.vue'),
                props: true,
                meta: {
                  label: 'Редактирование'
                }
              }
            ]
          },
          {
            path: 'phrases',
            name: 'camp.phrases',
            component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/phrases/PhrasesIndex.vue'),
            meta: {
              label: 'Переводы'
            }
          },
          {

            path: 'news',
            name: 'camp.news.group',
            redirect: { name: 'camp.news.index' },
            component: () => import(/* webpackChunkName: 'camp' */ '@/components/TheEmptyRouterView.vue'),
            meta: {
              label: 'Новости'
            },
            children: [
              {
                path: '',
                name: 'camp.news.index',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/news/NewsList.vue'),
                meta: {

                  label: 'Список'
                }
              },
              {
                path: 'edit/:id',
                name: 'camp.news.edit',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/news/NewsEdit.vue'),
                meta: {

                  label: 'Редактирование'
                }
              },
              {
                path: 'add',
                name: 'camp.news.add',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/news/NewsAdd.vue'),
                meta: {

                  label: 'Добавление'
                }
              },
              {
                path: '/camp/news/category',
                name: 'camp.news.category.index',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/news/NewsCategoryList.vue'),
                meta: {

                  label: 'Список'
                }
              },
              {
                path: '/camp/news/category/edit/:id',
                name: 'camp.news.category.edit',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/news/NewsCategoryEdit.vue'),
                meta: {

                  label: 'Редактирование'
                }
              },
              {
                path: '/camp/news/category/add',
                name: 'camp.news.category.add',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/news/NewsCategoryAdd.vue'),
                meta: {

                  label: 'Добавление'
                }
              }
            ]
          },
          {
            path: 'slides',
            meta: {
              label: 'Слайды'
            },
            name: 'camp.slides.group',
            redirect: { name: 'camp.slides.index' },
            component: emptyRouterView,
            children: [
              {
                path: '',
                name: 'camp.slides.index',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/slides/SlidesIndex.vue'),
                meta: {
                  label: 'Список'
                }
              },
              {
                path: 'add',
                name: 'camp.slides.add',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/slides/SlidesAdd.vue'),
                meta: {
                  label: 'Добавление'
                }
              },
              {
                path: 'edit/:id',
                name: 'camp.slides.edit',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/slides/SlidesEdit.vue'),
                props: true,
                meta: {
                  label: 'Редактирование'
                }
              }
            ]
          },
          {
            path: 'sliders',
            meta: {
              label: 'Слайдеры'
            },
            name: 'camp.sliders.group',
            redirect: { name: 'camp.sliders.index' },
            component: emptyRouterView,
            children: [
              {
                path: '',
                name: 'camp.sliders.index',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/sliders/SlidersIndex.vue'),
                meta: {
                  label: 'Список'
                }
              },
              {
                path: 'add',
                name: 'camp.sliders.add',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/sliders/SlidersAdd.vue'),
                meta: {
                  label: 'Добавление'
                }
              }
            ]
          },

          {
            path: 'account-logs',
            name: 'camp.account-logs.index',
            component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/account-logs/AccountLogsList.vue'),
            meta: {
              label: 'Логи авторизации'
            }
          },
          {
            path: 'recurrent-payments',
            meta: {
              label: 'Бустеры'
            },
            name: 'camp.recurrent-payments.group',
            redirect: { name: 'camp.recurrent-payments.index' },
            component: emptyRouterView,
            children: [
              {
                path: '',
                name: 'camp.recurrent-payments.index',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/recurrent-payments/RecurrentPaymentsList.vue'),
                meta: {
                  label: 'Список'
                }
              }
            ]
          },
          {
            path: 'action-logs',
            name: 'camp.action-logs.index',
            component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/action-logs/ActionLogsList.vue'),
            meta: {
              label: 'Логи действий'
            }
          },
          {
            path: 'search-logs',
            name: 'camp.search-logs.index',
            component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/search-logs/SearchLogsList.vue'),
            meta: {
              label: 'Логи поиска на сайте'
            }
          },
          {
            path: 'services',
            name: 'camp.services.group',
            redirect: { name: 'camp.services.index' },
            component: emptyRouterView,
            meta: {
              label: 'Услуги'
            },
            children: [
              {
                path: '',
                name: 'camp.services.index',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/services/services/ServicesIndex.vue'),
                meta: {
                  label: 'Список'
                }
              },
              {
                path: 'edit/:id',
                name: 'camp.services.edit',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/services/services/ServicesEdit.vue'),
                props: true,
                meta: {
                  label: 'Редактирование'
                }
              },
              {
                path: 'add',
                name: 'camp.services.add',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/services/services/ServicesAdd.vue'),
                meta: {
                  label: 'Добавление'
                }
              }
            ]
          },
          {
            path: 'technical',
            name: 'camp.technical.group',
            redirect: { name: 'camp.technical' },
            component: emptyRouterView,
            meta: {
              label: 'Правила проверки технической возможности'
            },
            children: [
              {
                path: '',
                name: 'camp.technical',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/services/technical/TechnicalIndex.vue'),
                meta: {
                  label: 'Список'
                }
              },
              {
                path: 'add',
                name: 'camp.technical.add',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/services/technical/TechnicalAdd.vue'),
                meta: {
                  label: 'Добавить'
                }
              },
              {
                path: 'edit/:id',
                name: 'camp.technical.edit',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/services/technical/TechnicalEdit.vue'),
                props: true,
                meta: {
                  label: 'Редактировать'
                }
              }
            ]
          },
          {
            path: 'packages',
            name: 'camp.packages.group',
            redirect: { name: 'camp.packages.index' },
            component: emptyRouterView,
            meta: {
              label: 'Пакеты'
            },
            children: [
              {
                path: '',
                name: 'camp.packages.index',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/services/packages/PackagesIndex.vue'),
                meta: {
                  label: 'Список'
                }
              },
              {
                path: 'edit/:id',
                name: 'camp.packages.edit',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/services/packages/PackagesEdit.vue'),
                props: true,
                meta: {
                  label: 'Редактирование'
                }
              },
              {
                path: '/camp/packages/add',
                name: 'camp.packages.add',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/services/packages/PackagesAdd.vue'),
                meta: {
                  label: 'Создание'
                }
              }
            ]
          },
          {
            path: 'main_services',
            name: 'camp.main_services.group',
            redirect: { name: 'camp.main_services.index' },
            component: emptyRouterView,
            meta: {
              label: 'Основные услуги пакета'
            },
            children: [
              {
                path: '',
                name: 'camp.main_services.index',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/services/main-services/MainServicesIndex.vue'),
                meta: {

                  label: 'Список'
                }
              },
              {
                path: 'edit/:id',
                name: 'camp.main_services.edit',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/services/main-services/MainServicesEdit.vue'),
                props: true,
                meta: {

                  label: 'Редактирование'
                }
              },
              {
                path: 'add',
                name: 'camp.main_services.add',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/services/main-services/MainServicesAdd.vue'),
                meta: {

                  label: 'Создание'
                }
              }
            ]
          },
          {
            path: 'rate_details',
            name: 'camp.rate_details.group',
            redirect: { name: 'camp.rate_details.index' },
            component: emptyRouterView,
            meta: {
              label: 'Детали тарифа'
            },
            children: [
              {
                path: '',
                name: 'camp.rate_details.index',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/services/rate-details/RateDetailsIndex.vue'),
                meta: {
                  label: 'Список'
                }
              },
              {
                path: 'edit/:id',
                name: 'camp.rate_details.edit',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/services/rate-details/RateDetailsEdit.vue'),
                props: true,
                meta: {
                  label: 'Редактирование'
                }
              },
              {
                path: 'add',
                name: 'camp.rate_details.add',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/services/rate-details/RateDetailsAdd.vue'),
                meta: {
                  label: 'Создание'
                }
              }
            ]
          },
          {
            path: 'communication_channels',
            name: 'camp.communication_channels.group',
            redirect: { name: 'camp.communication_channels.index' },
            component: emptyRouterView,
            meta: {
              label: 'Управление способами связи'
            },
            children: [
              {
                path: '',
                name: 'camp.communication_channels.index',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/services/communication-channels/CommunicationChannelsIndex.vue'),
                meta: {
                  label: 'Список'
                }
              },
              {
                path: 'edit/:id',
                name: 'camp.communication_channels.edit',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/services/communication-channels/CommunicationChannelsEdit.vue'),
                props: true,
                meta: {
                  label: 'Редактирование'
                }
              },
              {
                path: 'add',
                name: 'camp.communication_channels.add',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/services/communication-channels/CommunicationChannelsAdd.vue'),
                meta: {
                  label: 'Создание'
                }
              }
            ]
          },
          {
            path: 'additional_services',
            name: 'camp.additional_services.group',
            redirect: { name: 'camp.additional_services.index' },
            component: emptyRouterView,
            meta: {
              label: 'Дополнительные услуги пакета'
            },
            children: [
              {
                path: '',
                name: 'camp.additional_services.index',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/services/additional-services/AdditionalServicesIndex.vue'),
                meta: {
                  label: 'Список'
                }
              },
              {
                path: 'edit/:id',
                name: 'camp.additional_services.edit',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/services/additional-services/AdditionalServicesEdit.vue'),
                props: true,
                meta: {
                  label: 'Редактирование'
                }
              },
              {
                path: 'add',
                name: 'camp.additional_services.add',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/services/additional-services/AdditionalServicesAdd.vue'),
                meta: {
                  label: 'Создание'
                }
              }
            ]
          },
          {
            path: 'subservice_texts',
            name: 'camp.subservice_texts.group',
            redirect: { name: 'camp.subservice_texts.index' },
            component: emptyRouterView,
            meta: {
              label: 'Дополнительные текстовые данные об основной услуге'
            },
            children: [
              {
                path: '',
                name: 'camp.subservice_texts.index',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/services/subservice-texts/SubserviceTextsIndex.vue'),
                meta: {
                  label: 'Список'
                }
              },
              {
                path: 'edit/:id',
                name: 'camp.subservice_texts.edit',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/services/subservice-texts/SubserviceTextsEdit.vue'),
                props: true,
                meta: {
                  label: 'Редактирование'
                }
              },
              {
                path: 'add',
                name: 'camp.subservice_texts.add',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/services/subservice-texts/SubserviceTextsAdd.vue'),
                meta: {
                  label: 'Создание'
                }
              }
            ]
          },
          {
            path: 'subservice_selects',
            name: 'camp.subservice_selects.group',
            redirect: { name: 'camp.subservice_selects.index' },
            component: emptyRouterView,
            meta: {
              label: 'Дополнительные данные c вариантами выбора в основной услуге'
            },
            children: [
              {
                path: '',
                name: 'camp.subservice_selects.index',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/services/subservice-selects/SubserviceSelectsIndex.vue'),
                meta: {
                  label: 'Список'
                }
              },
              {
                path: 'edit/:id',
                name: 'camp.subservice_selects.edit',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/services/subservice-selects/SubserviceSelectsEdit.vue'),
                props: true,
                meta: {
                  label: 'Редактирование'
                }
              },
              {
                path: 'add',
                name: 'camp.subservice_selects.add',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/services/subservice-selects/SubserviceSelectsAdd.vue'),
                meta: {
                  label: 'Создание'
                }
              }
            ]
          },
          {
            path: 'subservice_options',
            name: 'camp.subservice_options.group',
            redirect: { name: 'camp.subservice_options.index' },
            component: emptyRouterView,
            meta: {
              label: 'Дополнительные данные c вариантами выбора в основной услуге'
            },
            children: [
              {
                path: '',
                name: 'camp.subservice_options.index',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/services/subservice-options/SubserviceOptionsIndex.vue'),
                meta: {
                  label: 'Список'
                }
              },
              {
                path: 'edit/:id',
                name: 'camp.subservice_options.edit',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/services/subservice-options/SubserviceOptionsEdit.vue'),
                props: true,
                meta: {
                  label: 'Редактирование'
                }
              },
              {
                path: 'add',
                name: 'camp.subservice_options.add',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/services/subservice-options/SubserviceOptionsAdd.vue'),
                meta: {
                  label: 'Создание'
                }
              }
            ]
          },
          {
            path: 'single_services',
            name: 'camp.single_services.group',
            redirect: { name: 'camp.single_services.index' },
            component: emptyRouterView,
            meta: {
              label: 'Отдельные услуги'
            },
            children: [
              {
                path: '',
                name: 'camp.single_services.index',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/services/single-services/SingleServicesIndex.vue'),
                meta: {
                  label: 'Список'
                }
              },
              {
                path: 'edit/:id',
                name: 'camp.single_services.edit',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/services/single-services/SingleServicesEdit.vue'),
                props: true,
                meta: {
                  label: 'Редактирование'
                }
              },
              {
                path: 'add',
                name: 'camp.single_services.add',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/services/single-services/SingleServicesAdd.vue'),
                meta: {
                  label: 'Создание'
                }
              },
              {
                path: 'advantages',
                name: 'camp.advantages.group',
                redirect: { name: 'camp.advantages.index' },
                component: emptyRouterView,
                meta: {
                  label: 'Преимущества (списки)'
                },
                children: [
                  {
                    path: '',
                    name: 'camp.advantages.index',
                    component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/services/advantages/AdvantagesIndex.vue'),
                    meta: {
                      label: 'Список'
                    }
                  },
                  {
                    path: 'edit/:id',
                    name: 'camp.advantages.edit',
                    component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/services/advantages/AdvantagesEdit.vue'),
                    props: true,
                    meta: {
                      label: 'Редактирование'
                    }
                  },
                  {
                    path: 'add',
                    name: 'camp.advantages.add',
                    component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/services/advantages/AdvantagesAdd.vue'),
                    meta: {
                      label: 'Создание'
                    }
                  },
                  {
                    path: 'advantage_items',
                    name: 'camp.advantage_items.group',
                    redirect: { name: 'camp.advantage_items.index' },
                    component: emptyRouterView,
                    meta: {
                      label: 'Преимущества (элементы списков)'
                    },
                    children: [
                      {
                        path: '',
                        name: 'camp.advantage_items.index',
                        component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/services/advantage-items/AdvantageItemsIndex.vue'),
                        meta: {
                          label: 'Список'
                        }
                      },
                      {
                        path: 'edit/:id',
                        name: 'camp.advantage_items.edit',
                        component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/services/advantage-items/AdvantageItemsEdit.vue'),
                        props: true,
                        meta: {
                          label: 'Редактирование'
                        }
                      },
                      {
                        path: 'add',
                        name: 'camp.advantage_items.add',
                        component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/services/advantage-items/AdvantageItemsAdd.vue'),
                        meta: {
                          label: 'Создание'
                        }
                      }
                    ]
                  }
                ]
              },
              {
                path: 'blocks',
                name: 'camp.blocks.group',
                redirect: { name: 'camp.blocks.index' },
                component: emptyRouterView,
                meta: {
                  label: 'Блоки (списки)'
                },
                children: [
                  {
                    path: '',
                    name: 'camp.blocks.index',
                    component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/services/blocks/BlocksIndex.vue'),
                    meta: {
                      label: 'Список'
                    }
                  },
                  {
                    path: 'edit/:id',
                    name: 'camp.blocks.edit',
                    component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/services/blocks/BlocksEdit.vue'),
                    props: true,
                    meta: {
                      label: 'Редактирование'
                    }
                  },
                  {
                    path: 'add',
                    name: 'camp.blocks.add',
                    component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/services/blocks/BlocksAdd.vue'),
                    meta: {
                      label: 'Создание'
                    }
                  },
                  {
                    path: 'block_items',
                    name: 'camp.blocks.block_items',
                    redirect: { name: 'camp.block_items.index' },
                    component: emptyRouterView,
                    meta: {
                      label: 'Блоки (элементы списков)'
                    },
                    children: [
                      {
                        path: '',
                        name: 'camp.block_items.index',
                        component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/services/block-items/BlockItemsIndex.vue'),
                        meta: {
                          label: 'Список'
                        }
                      },
                      {
                        path: 'edit/:id',
                        name: 'camp.block_items.edit',
                        component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/services/block-items/BlockItemsEdit.vue'),
                        props: true,
                        meta: {
                          label: 'Редактирование'
                        }
                      },
                      {
                        path: 'add',
                        name: 'camp.block_items.add',
                        component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/services/block-items/BlockItemsAdd.vue'),
                        meta: {
                          label: 'Создание'
                        }
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            path: 'menu',
            name: 'camp.menu.group',
            redirect: { name: 'camp.menu.index' },
            component: emptyRouterView,
            meta: {
              label: 'Статичные страницы - Меню'
            },
            children: [
              {
                path: '',
                name: 'camp.menu.index',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/pages/PageMenuList.vue'),
                meta: {
                  requiredAuth: true,
                  label: 'Список'
                }
              },
              {
                path: 'add/:parent',
                name: 'camp.menu.add',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/pages/PageMenuAdd.vue'),
                props: true,
                meta: {
                  requiredAuth: true,
                  label: 'Добавление'
                }
              },
              {
                path: 'edit/:id',
                name: 'camp.menu.edit',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/pages/PageMenuEdit.vue'),
                props: true,
                meta: {
                  requiredAuth: true,
                  label: 'Редактирование'
                }
              }
            ]
          },
          {
            path: 'publications',
            name: 'camp.publications.group',
            redirect: { name: 'camp.publications.index' },
            component: emptyRouterView,
            meta: {
              label: 'Страницы'
            },
            children: [
              {
                path: '',
                name: 'camp.publications.index',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/pages/PublicationList.vue'),
                meta: {
                  requiredAuth: true,
                  label: 'Страницы'
                }
              },
              {
                path: 'edit/:id',
                name: 'camp.publications.edit',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/pages/PublicationEdit.vue'),
                props: true,
                meta: {
                  requiredAuth: true,
                  label: 'Редактирование'
                }
              },
              {
                path: 'add',
                name: 'camp.publications.add',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/pages/PublicationAdd.vue'),
                props: true,
                meta: {
                  requiredAuth: true,
                  label: 'Добавление'
                }
              }
            ]
          },
          {
            path: 'knowledge',
            name: 'camp.knowledge.group',
            redirect: { name: 'camp.knowledge.sub-category.index' },
            component: emptyRouterView,
            meta: {
              label: 'База знаний'
            },
            children: [
              {
                path: 'sub-category',
                name: 'camp.knowledge.sub-category.group',
                redirect: { name: 'camp.knowledge.sub-category.index' },
                component: emptyRouterView,
                meta: {
                  label: 'Категории'
                },
                children: [
                  {
                    path: '',
                    name: 'camp.knowledge.sub-category.index',
                    component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/knowledge/subcategory/SubCategoryIndex.vue'),
                    meta: {
                      label: 'Список'
                    }
                  },
                  {
                    path: 'edit/:id',
                    name: 'camp.knowledge.sub-category.edit',
                    component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/knowledge/subcategory/SubCategoryEdit.vue'),
                    props: true,
                    meta: {
                      label: 'Редактирование'
                    }
                  },
                  {
                    path: 'add',
                    name: 'camp.knowledge.sub-category.add',
                    component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/knowledge/subcategory/SubCategoryAdd.vue'),
                    props: true,
                    meta: {
                      label: 'Добавление'
                    }
                  }
                ]
              },
              {
                path: 'category',
                name: 'camp.knowledge.category.group',
                redirect: { name: 'camp.knowledge.category.index' },
                component: emptyRouterView,
                meta: {
                  label: 'Вложенные категории'
                },
                children: [
                  {
                    path: '',
                    name: 'camp.knowledge.category.index',
                    component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/knowledge/category/CategoryIndex.vue'),
                    meta: {
                      label: 'Список'
                    }
                  },
                  {
                    path: 'edit/:id',
                    name: 'camp.knowledge.category.edit',
                    component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/knowledge/category/CategoryEdit.vue'),
                    props: true,
                    meta: {
                      label: 'Редактирование'
                    }
                  },
                  {
                    path: 'add',
                    name: 'camp.knowledge.category.add',
                    component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/knowledge/category/CategoryAdd.vue'),
                    props: true,
                    meta: {
                      label: 'Добавление'
                    }
                  }
                ]
              },
              {
                path: 'question',
                name: 'camp.knowledge.question.group',
                redirect: { name: 'camp.knowledge.question.index' },
                component: emptyRouterView,
                meta: {
                  label: 'Вопросы'
                },
                children: [
                  {
                    path: '',
                    name: 'camp.knowledge.question.index',
                    component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/knowledge/question/QuestionIndex.vue'),
                    meta: {
                      label: 'Список'
                    }
                  },
                  {
                    path: 'edit/:id',
                    name: 'camp.knowledge.question.edit',
                    component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/knowledge/question/QuestionEdit.vue'),
                    props: true,
                    meta: {
                      label: 'Редактирование'
                    }
                  },
                  {
                    path: 'add',
                    name: 'camp.knowledge.question.add',
                    component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/knowledge/question/QuestionAdd.vue'),
                    props: true,
                    meta: {
                      label: 'Добавление'
                    }
                  },
                  {
                    path: 'answer',
                    name: 'camp.knowledge.answer.group',
                    redirect: { name: 'camp.knowledge.answer.index' },
                    component: emptyRouterView,
                    meta: {
                      label: 'Ответы'
                    },
                    children: [
                      {
                        path: '',
                        name: 'camp.knowledge.answer.index',
                        component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/knowledge/answer/AnswerIndex.vue'),
                        meta: {
                          label: 'Список'
                        }
                      },
                      {
                        path: 'edit/:id',
                        name: 'camp.knowledge.answer.edit',
                        component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/knowledge/answer/AnswerEdit.vue'),
                        props: true,
                        meta: {
                          label: 'Редактирование'
                        }
                      },
                      {
                        path: 'add',
                        name: 'camp.knowledge.answer.add',
                        component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/knowledge/answer/AnswerAdd.vue'),
                        props: true,
                        meta: {
                          label: 'Добавление'
                        }
                      }
                    ]
                  }
                ]
              },
              {
                path: 'video',
                name: 'camp.knowledge.video.group',
                redirect: { name: 'camp.knowledge.video.index' },
                component: emptyRouterView,
                meta: {
                  label: 'Видео'
                },
                children: [
                  {
                    path: '',
                    name: 'camp.knowledge.video.index',
                    component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/knowledge/video/VideoIndex.vue'),
                    meta: {
                      label: 'Список'
                    }
                  },
                  {
                    path: 'edit/:id',
                    name: 'camp.knowledge.video.edit',
                    component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/knowledge/video/VideoEdit.vue'),
                    props: true,
                    meta: {
                      label: 'Редактирование'
                    }
                  },
                  {
                    path: 'add',
                    name: 'camp.knowledge.video.add',
                    component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/knowledge/video/VideoAdd.vue'),
                    props: true,
                    meta: {
                      label: 'Добавление'
                    }
                  }
                ]
              }
            ]
          },
          {
            path: 'auction',
            name: 'camp.auction',
            redirect: { name: 'camp.auction.index' },
            component: emptyRouterView,
            meta: {
              label: 'Аукционы'
            },
            children: [
              {
                path: '',
                name: 'camp.auction.index',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/auction/AuctionList.vue'),
                meta: {
                  label: 'Список'
                }
              },
              {
                path: 'edit/:id',
                name: 'camp.auction.edit',
                props: true,
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/auction/AuctionEdit.vue'),
                meta: {
                  label: 'Редактирование'
                }
              },
              {
                path: 'add/:id',
                name: 'camp.auction.add',
                props: true,
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/auction/AuctionAdd.vue'),
                meta: {
                  label: 'Добавление'
                }
              },
              {
                path: 'auction-page',
                name: 'camp.auctionPage.index',
                component: emptyRouterView,
                meta: {
                  label: 'Страницы аукционов'
                },
                children: [
                  {
                    path: 'edit/:id',
                    name: 'camp.auctionPage.edit',
                    props: true,
                    component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/auction/pages/AuctionPageEdit.vue'),
                    meta: {
                      label: 'Редактирование'
                    }
                  },
                  {
                    path: 'add/:parent',
                    name: 'camp.auctionPage.add',
                    props: true,
                    component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/auction/pages/AuctionPageAdd.vue'),
                    meta: {
                      label: 'Добавление'
                    }
                  }
                ]
              }
            ]
          },
          {
            path: 'idphone/category',
            name: 'camp.idphone.category',
            redirect: { name: 'camp.idphone.category.index' },
            component: emptyRouterView,
            meta: {
              requiredAuth: true,
              label: 'Категории меню IDphone'
            },
            children: [
              {
                path: '',
                name: 'camp.idphone.category.index',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/idphone/category/IdphoneCategoryIndex.vue'),
                meta: {
                  label: 'Список'
                }
              },
              {
                path: 'edit/:id',
                name: 'camp.idphone.category.edit',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/idphone/category/IdphoneCategoryEdit.vue'),
                meta: {
                  label: 'Редактирование'
                }
              }
            ]
          },
          {
            path: 'idphone/service',
            name: 'camp.idphone.service',
            redirect: { name: 'camp.idphone.service.index' },
            component: emptyRouterView,
            meta: {
              requiredAuth: true,
              label: 'Услуги IDphone'
            },
            children: [
              {
                path: '',
                name: 'camp.idphone.service.index',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/idphone/service/IdphoneServiceIndex.vue'),
                meta: {
                  label: 'Список'
                }
              },
              {
                path: 'edit/:id',
                name: 'camp.idphone.service.edit',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/idphone/service/IdphoneServiceEdit.vue'),
                meta: {
                  label: 'Редактирование'
                }
              },
              {
                path: 'add',
                name: 'camp.idphone.service.add',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/idphone/service/IdphoneServiceAdd.vue'),
                meta: {
                  label: 'Редактирование'
                }
              }
            ]
          },
          {
            path: 'interview/interview',
            name: 'camp.interview.interview',
            redirect: { name: 'camp.interview.interview.index' },
            component: emptyRouterView,
            meta: {
              label: 'Опросы'
            },
            children: [
              {
                path: '',
                name: 'camp.interview.interview.index',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/interview/interview/InterviewIndex.vue'),
                meta: {
                  label: 'Список'
                }
              },
              {
                path: 'edit/:id',
                name: 'camp.interview.interview.edit',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/interview/interview/InterviewEdit.vue'),
                meta: {
                  label: 'Редактирование'
                }
              },
              {
                path: 'add',
                name: 'camp.interview.interview.add',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/interview/interview/InterviewAdd.vue'),
                meta: {
                  label: 'Добавление'
                }
              }
            ]
          },
          {
            path: 'interview/variant',
            name: 'camp.interview.variant',
            redirect: { name: 'camp.interview.variant.index' },
            component: emptyRouterView,
            meta: {
              label: 'Варианты ответов'
            },
            children: [
              {
                path: '',
                name: 'camp.interview.variant.index',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/interview/variant/InterviewVariantIndex.vue'),
                meta: {
                  label: 'Список'
                }
              },
              {
                path: 'edit/:id',
                name: 'camp.interview.variant.edit',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/interview/variant/InterviewVariantEdit.vue'),
                meta: {
                  label: 'Редактирование'
                }
              },
              {
                path: 'add',
                name: 'camp.interview.variant.add',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/interview/variant/InterviewVariantAdd.vue'),
                meta: {
                  label: 'Добавление'
                }
              }
            ]
          },
          {
            path: 'purchases',
            name: 'camp.purchases',
            redirect: { name: 'camp.purchases.index' },
            component: emptyRouterView,
            meta: {
              label: 'Закупки'
            },
            children: [
              {
                path: '',
                name: 'camp.purchases.index',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/purchases/PurchaseList.vue'),
                meta: {
                  label: 'Список'
                }
              },
              {
                path: 'edit/:id',
                name: 'camp.purchases.edit',
                props: true,
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/purchases/PurchaseEdit.vue'),
                meta: {
                  label: 'Редактирование'
                }
              },
              {
                path: 'add/:parent',
                name: 'camp.purchases.add',
                props: true,
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/purchases/PurchaseAdd.vue'),
                meta: {
                  label: 'Добавление'
                }
              },
              {
                path: 'purchase-page',
                name: 'camp.purchasePage.index',
                redirect: { name: 'camp.purchasePage.index' },
                component: emptyRouterView,
                meta: {
                  label: 'Страницы закупок'
                },
                children: [
                  {
                    path: 'edit/:id',
                    name: 'camp.purchasePage.edit',
                    props: true,
                    component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/purchases/pages/PurchasePageEdit.vue')
                  },
                  {
                    path: 'add/:parent',
                    name: 'camp.purchasePage.add',
                    props: true,
                    component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/purchases/pages/PurchasePageAdd.vue')
                  }
                ]
              }
            ]
          },
          {
            path: 'operator',
            name: 'camp.operator',
            redirect: { name: 'camp.operator.index' },
            component: emptyRouterView,
            meta: {
              label: 'Операторы связи'
            },
            children: [
              {
                path: '',
                name: 'camp.operator.index',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/operator/OperatorList.vue'),
                meta: {
                  label: 'Список'
                }
              },
              {
                path: 'edit/:id',
                name: 'camp.operator.edit',
                props: true,
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/operator/OperatorEdit.vue'),
                meta: {
                  label: 'Редактирование'
                }
              },
              {
                path: 'add/:parent',
                name: 'camp.operator.add',
                props: true,
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/operator/OperatorAdd.vue'),
                meta: {
                  label: 'Добавление'
                }
              }
            ]
          },
          {
            path: 'traffic-left',
            name: 'traffic.left',
            redirect: { name: 'camp.traffic.left.index' },
            component: emptyRouterView,
            meta: {
              label: 'Остатки трафика'
            },
            children: [
              {
                path: '',
                name: 'camp.traffic.left.index',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/traffic-left/TrafficLeftList.vue'),
                meta: {
                  label: 'Список'
                }
              },
              {
                path: 'edit/:id',
                name: 'camp.traffic.left.edit',
                props: true,
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/traffic-left/TrafficLeftEdit.vue'),
                meta: {
                  label: 'Редактирование'
                }
              },
              {
                path: 'add/',
                name: 'camp.traffic.left.add',
                props: true,
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/traffic-left/TrafficLeftAdd.vue'),
                meta: {
                  label: 'Добавление'
                }
              }
            ]
          },
          {
            path: 'tv/rubrics',
            name: 'camp.tv.rubrics',
            redirect: { name: 'camp.tv.rubrics.index' },
            component: emptyRouterView,
            meta: {
              label: 'Рубрики телевидения'
            },
            children: [
              {
                path: '',
                name: 'camp.tv.rubrics.index',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/tv/rubric/RubricList.vue'),
                meta: {
                  label: 'Список'
                }
              },
              {
                path: 'edit/:id',
                name: 'camp.tv.rubrics.edit',
                props: true,
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/tv/rubric/RubricEdit.vue'),
                meta: {
                  label: 'Редактирование'
                }
              },
              {
                path: 'add',
                name: 'camp.tv.rubrics.add',
                props: true,
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/tv/rubric/RubricAdd.vue'),
                meta: {
                  label: 'Добавление'
                }
              }
            ]
          },
          {
            path: 'tv/video_request',
            name: 'camp.tv.video_request',
            redirect: { name: 'camp.tv.video_request.index' },
            component: emptyRouterView,
            meta: {
              label: 'Видео по запросу'
            },
            children: [
              {
                path: '',
                name: 'camp.tv.video_request.index',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/tv/video-request/VideoRequestList.vue'),
                meta: {
                  requiredAuth: true,
                  label: 'Список'
                }
              },
              {
                path: 'edit/:id',
                name: 'camp.tv.video_request.edit',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/tv/video-request/VideoRequestEdit.vue'),
                meta: {
                  requiredAuth: true,
                  label: 'Редактирование'
                }
              },
              {
                path: 'add',
                name: 'camp.tv.video_request.add',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/tv/video-request/VideoRequestAdd.vue'),
                meta: {
                  requiredAuth: true,
                  label: 'Добавление'
                }
              }
            ]
          },
          {
            path: 'tv/channels',
            name: 'camp.tv.channels',
            redirect: { name: 'camp.tv.channels.index' },
            component: emptyRouterView,
            meta: {
              label: 'Каналы телевидения(программа передач)'
            },
            children: [
              {
                path: '',
                name: 'camp.tv.channels.index',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/tv/channel/ChannelList.vue'),
                meta: {
                  requiredAuth: true,
                  label: 'Список'
                }
              },
              {
                path: 'edit/:id',
                name: 'camp.tv.channels.edit',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/tv/channel/ChannelEdit.vue'),
                meta: {
                  requiredAuth: true,
                  label: 'Редактирование'
                }
              },
              {
                path: 'add',
                name: 'camp.tv.channels.add',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/tv/channel/ChannelAdd.vue'),
                meta: {
                  requiredAuth: true,
                  label: 'Добавление'
                }
              }
            ]
          },
          {
            path: 'error',
            name: 'camp.error',
            redirect: { name: 'camp.error.index' },
            component: emptyRouterView,
            meta: {
              label: 'Ошибки портала'
            },
            children: [
              {
                path: '',
                name: 'camp.error.index',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/errors/ErrorList.vue'),
                meta: {
                  label: 'Список'
                }
              }
            ]
          },
          {
            path: 'tv/channel_category',
            name: 'camp.tv.channel_category',
            redirect: { name: 'camp.tv.channel_category.index' },
            component: emptyRouterView,
            meta: {
              label: 'Категории каналов(программа передач)'
            },
            children: [
              {
                path: '',
                name: 'camp.tv.channel_category.index',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/tv/channel-category/ChannelCategoryList.vue'),
                meta: {
                  requiredAuth: true,
                  label: 'Список'
                }
              },
              {
                path: 'edit/:id',
                name: 'camp.tv.channel_category.edit',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/tv/channel-category/ChannelCategoryEdit.vue'),
                meta: {
                  requiredAuth: true,
                  label: 'Редактирование'
                }
              },
              {
                path: 'add',
                name: 'camp.tv.channel_category.add',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/tv/channel-category/ChannelCategoryAdd.vue'),
                meta: {
                  requiredAuth: true,
                  label: 'Добавление'
                }
              }
            ]
          },
          {
            path: 'tv/channel_packages',
            name: 'camp.tv.channel_packages',
            redirect: { name: 'camp.tv.channel_packages.index' },
            component: emptyRouterView,
            meta: {
              label: 'Пакеты каналов'
            },
            children: [
              {
                path: '',
                name: 'camp.tv.channel_packages.index',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/tv/package/PackageList.vue'),
                meta: {
                  requiredAuth: true,
                  label: 'Список'
                }
              },
              {
                path: 'edit/:id',
                name: 'camp.tv.channel_packages.edit',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/tv/package/PackageEdit.vue'),
                meta: {
                  requiredAuth: true,
                  label: 'Редактирование'
                }
              },
              {
                path: 'add',
                name: 'camp.tv.channel_packages.add',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/tv/package/PackageAdd.vue'),
                meta: {
                  requiredAuth: true,
                  label: 'Добавление'
                }
              }
            ]
          },
          {
            path: 'wifi',
            name: 'camp.wifi',
            redirect: { name: 'camp.wifi.index' },
            component: emptyRouterView,
            meta: {
              label: 'Wi-Fi'
            },
            children: [
              {
                path: '',
                name: 'camp.wifi.index',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/wifi/WifiList.vue'),
                meta: {
                  label: 'Список'
                }
              },
              {
                path: 'edit/:id',
                name: 'camp.wifi.edit',
                props: true,
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/wifi/WifiEdit.vue'),
                meta: {
                  label: 'Редактирование'
                }
              },
              {
                path: 'add',
                name: 'camp.wifi.add',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/wifi/WifiAdd.vue'),
                meta: {
                  label: 'Добавление'
                }
              }
            ]
          },
          {
            path: 'pss-point',
            name: 'camp.pss-point',
            redirect: { name: 'camp.pss-point.index' },
            component: () => import(/* webpackChunkName: 'camp' */ '@/components/TheEmptyRouterView.vue'),
            meta: {
              label: 'Пункты сети сервиса'
            },
            children: [
              {
                path: '',
                name: 'camp.pss-point.index',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/pss-point/PssPointList.vue'),
                meta: {
                  label: 'Список'
                }
              },
              {
                path: 'edit/:id',
                name: 'camp.pss-point.edit',
                props: true,
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/pss-point/PssPointEdit.vue'),
                meta: {
                  label: 'Редактирование'
                }
              },
              {
                path: 'add',
                name: 'camp.pss-point.add',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/pss-point/PssPointAdd.vue'),
                meta: {
                  label: 'Добавление'
                }
              },
              {
                path: '/camp/pss/category',
                name: 'camp.pss-categories.index',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/pss-point/PssCategoryList.vue'),
                meta: {

                  label: 'Список'
                }
              },
              {
                path: '/camp/pss/category/edit/:id',
                name: 'camp.pss-categories.edit',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/pss-point/PssCategoryEdit.vue'),
                meta: {

                  label: 'Редактирование'
                }
              },
              {
                path: '/camp/pss/category/add',
                name: 'camp.pss-categories.add',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/pss-point/PssCategoryAdd.vue'),
                meta: {

                  label: 'Добавление'
                }
              }
            ]
          },
          {
            path: 'how-it-works',
            name: 'camp.how-it-works',
            component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/demo/HowItWorks.vue'),
            meta: {
              label: 'Как это работает?'
            }
          },
          {
            path: 'log',
            name: 'camp.log.group',
            redirect: { name: 'camp.user-action-logs.index' },
            component: emptyRouterView,
            meta: {
              label: 'Логи'
            },
            children: [
              {
                path: '',
                name: 'camp.user-action-logs.index',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/logs/user-action-logs/UserActionLogsIndex.vue'),
                meta: {
                  label: 'Список'
                }
              }
            ]
          },
          {
            path: 'sms-logs',
            name: 'camp.sms-logs.index',
            component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/sms-logs/SmsLogsList.vue'),
            meta: {
              label: 'Логи смс'
            }
          },
          {
            path: 'sms-mo',
            name: 'camp.sms-mo.index',
            component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/sms-mo/SmsMoList.vue'),
            meta: {
              label: 'Ответы смс'
            }
          },
          {
            path: 'mass-verification',
            name: 'camp.mass-verification.index',
            component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/mass-verification/MassVerificationIndex.vue'),
            meta: {
              label: 'Массовая верификация'
            }
          },
          {
            path: 'push-campaign',
            name: 'camp.campaign.group',
            component: emptyRouterView,
            redirect: {
              name: 'camp.aitu.push.campaign.index'
            },
            meta: {
              label: 'Рассылки'
            },
            children: [
              {
                path: '',
                name: 'camp.aitu.push.campaign.index',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/push-campaign/PushCampaignList.vue'),
                meta: {
                  label: 'Список'
                }
              },
              {
                path: 'add',
                name: 'camp.aitu.push.campaign.add',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/push-campaign/PushCampaignAdd.vue'),
                meta: {
                  label: 'Создание'
                }
              },
              {
                path: '/:campaignId/view',
                name: 'camp.aitu.push.campaign.view',
                component: () => import(/* webpackChunkName: 'camp' */ '@/views/camp/push-campaign/PushCampaignView.vue'),
                meta: {
                  label: 'Просмотр'
                }
              }
            ]
          }
        ]
      }
    ]
  }
];
